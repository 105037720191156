<template>
    <div class="row">
        <div class="col-md-12">

            <span class="h4 titulosg">Actividades Académicas</span> <br>

                <div class="row mt-3 mb-3">
                    <div class="col-md-12">
                        <form @submit.prevent="funcionbuscarclase">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="Buscar"  v-model="palabrabuscar" >
                                <button class="btn btn-primary" type="submit" id="button-addon2"><i class="fas fa-search"></i> Buscar</button>
                                <button class="btn btn-outline-secondary" type="button" @click="consultarclases; palabrabuscar=''"> <i class="fas fa-undo-alt"></i> </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 d-flex">
                        <div class="me-3"> Total: <span class="badge bg-primary"> {{paginacion.total}} </span></div>
                        <div class="me-2">Por página </div>    
                        <div class="me-3"> 
                            <select class="form-select form-select-sm" v-model="registrosPorPagina">
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="100">150</option>
                            </select>
                        </div>
                        <div class="ms-2 me-2">
                            <span class="me-1">Página {{paginacion.current_page}} de {{paginacion.last_page}}</span>
                            <button type="button" class="btn btn-primary btn-sm  me-1 ms-2" @click="paginaActual = paginaActual-1" v-if="paginaActual!=1" > <i class="fas fa-angle-left"></i>  </button>
                            <button type="button" class="btn btn-primary btn-sm " @click="paginaActual = paginaActual+1" v-if="paginaActual!=paginacion.last_page"> <i class="fas fa-angle-right"></i>  </button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row g-3">
                            <div  class="col-md-2 text-end"><i class="fas fa-filter"></i> Grado:  </div>
                            <div class="col-md-4">
                                <select class="form-select form-select-sm"  v-model="gradoFiltro">
                                    <option  value="todo" selected>Todo</option>
                                    <option v-for="item in listagradosasignados" :key="item.idacaasignacionacademica" :value="item.grados.idacagradosgrupos">{{item.grados.nombregradogrupo}}</option>
                                </select>
                            </div>
                            <div class="col-md-2 text-end"> Asignatura: </div>
                            <div class="col-md-4">
                                <select class="form-select form-select-sm" v-model="asignaturaFiltro">
                                    <option value="todo" selected>Todo</option>
                                    <option v-for="item in lsitaasignaturas" :key="item.idacaasignacionacademica" :value="item.asignaturas.idacaasignaturas">{{item.asignaturas.descripcion}}</option>
                                </select>
                             </div>
                        </div>
                    </div>
                </div>

                <div class="bg-white p-3 mt-3 mb-3 card">

                    <div class="text-center mt-5 " v-if="loadclases">
                        <div class="spinner-border text-primary spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <br>
                        Cargando ...
                    </div>
                    
                    <div class="table-responsive" style="height: 350px; overflow-y: auto;">
                    <table class="table table-hover" v-if="!loadclases" id="example" >
                        <thead>
                            <tr>
                                <td>Titulo</td>
                                <td>Grado</td>
                                <td>Área/Asignatura</td>
                                <td><i class="far fa-calendar-alt"></i> Fecha</td>
                                <td><i class="far fa-calendar-alt"></i> Fin</td>
                                <td>Tipo</td>
                                <td>Estado</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in clases" :key="item.idacaclase" @click="verclase(item.idacaclase)">
                                <td> 
                                    <span class="d-inline-block text-truncate me-0 pe-0" style="max-width: 250px;" :title="item.titulos">
                                        {{item.titulos}}  
                                    </span>
                                </td>
                                <td> <strong> {{item.grupos.nombregradogrupo}}  </strong> </td>
                                <td>
                                    <span class="d-inline-block text-truncate" style="max-width: 200px;"> 
                                        {{item.asignaturas.descripcion}} 
                                    </span>
                                </td>
                                <td> {{formatoFecha(item.fechainicio)}}    </td>
                                <td>   {{formatoFecha(item.fechacierre)}}  </td>
                                <td> 
                                        <div class="bg-success" v-if="item.tipoactividad == 1" style="width: 15px; height: 15px;" title="Clase "></div>
                                        <div class="bg-warning" v-if="item.tipoactividad == 2" style="width: 15px; height: 15px;" title="Trabajo en casa "></div>
                                        <div class="bg-danger" v-if="item.tipoactividad == 3" style="width: 15px; height: 15px;" title="Actividad de Recuperación "></div>
                                </td>
                                <td> 
                                    <span class="badge bg-success" v-if="item.estadoclase == 1"> Activo </span> 
                                    <span class="badge bg-light text-secondary border" v-if="item.estadoclase == 2"> Inactivo </span> 
                                </td>
                            </tr>
                        </tbody>
                    </table>                            
                    </div>
                    
                    <div class="d-flex d-row mt-3 text-secondary">
                        <div class="bg-success align-middle me-2 ms-3" style="width: 15px; height: 15px;"></div>Clase 
                        <div class="bg-warning align-middle me-2 ms-3" style="width: 15px; height: 15px;"></div> Trabajo en casa 
                        <div class="bg-danger align-middle me-2 ms-3" style="width: 15px; height: 15px;"></div> Actividad de Recuperación
                    </div>

                </div>   
    
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { ref, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import moment from 'moment'

export default {
    setup(props) {

        const route = useRoute()
        const router = useRouter()

        //paginacion
        const paginacion = ref({})
        const registrosPorPagina = ref(50)
        const paginaActual = ref(1)

        const palabrabuscar = ref()
        const clases = ref([])        
 
        const gradoFiltro = ref('todo')
        const asignaturaFiltro = ref('todo')
        const listagradosasignados = ref([])
        const lsitaasignaturas = ref([])

        const loadclases = ref(false)

        watch([paginaActual,registrosPorPagina], () => {
            consultarclases()
        })

        watch(palabrabuscar,() => {
            if(palabrabuscar.value == ''){
                consultarclases()
            }
        })

        watch([gradoFiltro, asignaturaFiltro],() => {
            if(gradoFiltro.value == "todo" && asignaturaFiltro.value=="todo"){
                consultarclases()
            }else{
                filtrarClases()
            }
        })

        const consultarclases = () => {
                loadclases.value = true
                axios.get('/api/clases/'+registrosPorPagina.value+'?page='+paginaActual.value).then( response => {
                    clases.value = response.data.data
                    paginacion.value = response.data
                    loadclases.value = false
                })
        }

        const funcionbuscarclase = () => {
            
            if(palabrabuscar.value != null)
            {
                loadclases.value = true
                axios.get('/api/buscarclase/'+palabrabuscar.value).then( response => {
                    clases.value = response.data 
                    loadclases.value = false                    
                })
            }            
        }
        const filtrarClases = () => {
            loadclases.value = true
            axios.get('/api/filtrarclases/'+gradoFiltro.value+'/'+asignaturaFiltro.value+'/'+registrosPorPagina.value).then(response =>{
                clases.value = response.data.data
                paginacion.value = response.data
                loadclases.value = false
            })
        }
        
        const gradosAsignados = () => {
            axios.get('/api/gradosasignados/sinid').then( response => {
                listagradosasignados.value = response.data
            })
        }

        const asignaturasAsignadas = () => {
            axios.get('/api/asignaturasasignadas/sinid').then( response => {
                lsitaasignaturas.value = response.data
            })
        }

        const formatoFecha = (date) => {
            return moment(date).format('D[-]MMM[-]Y');
        }

        const formatoFechaHuman = (date) => {
            return moment(date).toNow();
        }

        const verclase = (id) => {
            router.push({ path: '/clase/'+id })
        }

        onMounted(() => {
            consultarclases()  
            gradosAsignados()  
            asignaturasAsignadas()  
        })
        
        return {
            paginacion,      
            paginaActual,      
            registrosPorPagina,
            clases,
            palabrabuscar,
            asignaturaFiltro,
            gradoFiltro,
            listagradosasignados,
            lsitaasignaturas,
            loadclases,

            //funciones
            formatoFecha,
            formatoFechaHuman,
            verclase,
            funcionbuscarclase,
            consultarclases
        }
    }
}
</script>

<style>

</style>
